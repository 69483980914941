<template>
  <a-modal
    title="新建"
    :width="580"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="电表初值">
              <a-input-number v-decorator="['value', {rules: [{required: true, message: '必填项，请填写信息'}]}]" :precision="2" style="width: 80%" />
            </a-form-item>
            <a-form-item label="备注">
              <a-input v-decorator="['remark', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      groupData: [],
      dataSource: []
    }
  }
}
</script>
